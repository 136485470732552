import React from 'react'
import Fade from 'react-reveal/Fade'

const Foot = ({title="Improve Your Digital Agency Revenue Now", description=`All of your digital marketing efforts revolve around your website. 
So regardless of whether your marketing strategies are extraordinary, they won't be powerful if your website design can't attract your visitors and convert them.
`, className = 'style2', children}) => (
  <section id="Foot" className={`wrapper ${className} special fade inactive`}>
    <Fade duration={2500}>
      <div className="container">
        <header>
          <h2>{title}</h2>
          <p>{description}</p> 
          
          {children}
        </header>
  
        <form method="post" name="contact" className="cta" data-netlify="true" netlify-honeypot="bot-field" hidden>
        <input type="hidden" name="form-name" value="contact" />
          <div className="row gtr-uniform gtr-50">
            <div className="col-7 col-12-xsmall">
              <input
                aria-label="Your email"
                type="email"
                name="email"
                id="email"
                placeholder="Your Email Address"
              />
            </div>
            <div className="col-5 col-12-xsmall">
              <input
                aria-label="Submit the form"
                id="submitForm"
                type="submit"
                value="SCHEDULE MEETING"
                className="fit primary"
              />
            </div>
          </div>
        </form>
      </div>
    </Fade>
  </section>
)
export default React.memo(Foot)
